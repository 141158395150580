/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/CodeByZach/pace@1.2.4/pace.min.js
 * - /npm/quicklink@2.3.0/dist/quicklink.umd.js
 * - /npm/jquery@3.7.0/dist/jquery.min.js
 * - /npm/alertify-js@0.3.12/lib/alertify.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
